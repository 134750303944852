import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { useSession } from 'src/sdk/session'
import { graphql } from 'gatsby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ProductGallery from 'src/components/sections/ProductGallery'
import { ITEMS_PER_PAGE, ITEMS_PER_PAGE_TABLET } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import SROnly from 'src/components/ui/SROnly'
import { mark } from 'src/sdk/tests/mark'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useCampaignContext } from 'src/contexts/campaign-context'
import { FilterContextProvider } from 'src/contexts/FilterContext/filter-context'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) =>
  useMemo(() => href && parseSearchState(new URL(href)), [href])

function Page(props: Props) {
  const { setCampaignPage } = useCampaignContext()
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('Search', person)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  setCampaignPage(false)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = {
      type: 'Search',
      originalType: 'Search',
    }
  }, [])

  const {
    data: { site, cmsHome },
  } = props

  const { locale } = useSession()
  const searchParams = useSearchParams(props.location)
  const title = 'Resultados de busca | Vivara'
  const description = 'Tornando toda história única e especial'
  const { width } = useWindowDimensions()

  if (!searchParams) {
    return null
  }

  const { term, base } = searchParams

  const ITEM_PER_PAGE_VERIFIED = width
    ? width < 768
      ? ITEMS_PER_PAGE
      : width <= 1279
      ? ITEMS_PER_PAGE_TABLET
      : ITEMS_PER_PAGE
    : ITEMS_PER_PAGE

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEM_PER_PAGE_VERIFIED}
      {...searchParams}
    >
      <FilterContextProvider>
        {/* SEO */}
        <GatsbySeo
          noindex
          nofollow
          language={locale}
          title={title}
          description={description}
          openGraph={{
            type: 'website',
            title,
            description: site?.siteMetadata?.description ?? '',
          }}
        />

        {/*
          Sections: Components imported from '../components/sections' only.
          Do not import or render components from any other folder in here.
        */}
        <SROnly as="h1" text={title} />

        <ProductGallery
          title="Search Results"
          cmsHome={cmsHome}
          term={term}
          base={base}
          sendInsiderEvent
        />
        <JsonLd
          json={{
            '@context': 'https://schema.org',
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: window.location.href,
              actionPlatform: [
                'http://schema.org/DesktopWebPlatform',
                'http://schema.org/MobileWebPlatform',
              ],
            },
            'query-input': term,
          }}
        />
      </FilterContextProvider>
    </SearchProvider>
  )
}

export const query = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
    cmsHome {
      sections {
        name
        data
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)
